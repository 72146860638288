.osmosisIcon {
    width: 120px;
    display: flex;
}

.osmosisIcon.animated :global(#waters) {
    animation: waters 5s linear infinite alternate forwards;
}

.osmosisIcon.animated :global(#back) :global(#waters) {
    animation: wiggleReverse 5s linear infinite alternate forwards;
}

.osmosisIcon.animated :global(#blueWave) {
    animation: blueWave 4.5s linear infinite alternate forwards;
}

.osmosisIcon.animated :global(#purpleWave) {
    animation: purpleWave 4s linear infinite alternate forwards;
}

.osmosisIcon.animated :global(#bottomWater) {
    animation: bottomWater 3s linear infinite alternate forwards;
}

@keyframes waters {
    0% { transform: translate(-20, 0) rotate(0deg); }
    15% { transform: translate(-14, 0) rotate(-5deg); }
    33% { transform: translate(0, 0) rotate(-8deg); }
    66% { transform: translate(5, 0) rotate(0deg); }
    85% { transform: translate(10, 0) rotate(8deg); }
    100% { transform: translate(0, 0) rotate(3deg); }
}

@keyframes wiggleReverse {
    0% { transform: rotate(0deg) }
    33% { transform: rotate(-15deg) }
    66% { transform: rotate(15deg) }
    100% { transform: rotate(0deg) }
}

@keyframes blueWave {
    0% { transform: translate(8px, 2px) }
    15% { transform: translate(0, 6px) }
    30% { transform: translate(-34px, -2px) }
    45% { transform: translate(-60px, 4px) }
    70% { transform: translate(-70px, -4px) }
    100% { transform: translate(-90px, -8px) }
}

@keyframes purpleWave {
    0% { transform: translate(-8px, 4px) }
    20% { transform: translate(0, 6px) }
    35% { transform: translate(34px, -2px) }
    55% { transform: translate(60px, 2px) }
    75% { transform: translate(70px, -4px) }
    100% { transform: translate(80px, -6px) }
}

@keyframes bottomWater {
    0% { transform: translate(-30px, 0) rotate(10deg); }
    15% { transform: translate(-10px, 0) rotate(20deg); }
    40% { transform: translate(-4px, 0) rotate(6deg); }
    60% { transform: translate(14px, 0) rotate(-6deg); }
    80% { transform: translate(22px, 0) rotate(6deg); }
    100% { transform: translate(30px, 0) }
}
