.wordEffect {
  overflow: visible;
  display: flex;
}

.wordEffect svg .aniText > * {
  fill: transparent;
}

:global(.animation) .wordEffect svg .aniText > * {
  text-transform: uppercase;
  animation: stroke 4s forwards;
  stroke: rgba(255, 255, 255, 255);
  stroke-width: 0;
}

:global(.animation) .textMoveAni {
  animation: moving 2s forwards;
  animation-delay: 1.5s;
}

@keyframes moving {
  from {transform: translateX(0px);}
  to {transform: translateX(-48px);}
}

@keyframes stroke {
  0% {
    fill: rgba(255, 255, 255, 0);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 1;
  }
  70% {
    fill: rgba(255, 255, 255, 0);
  }
  80% {
    fill: rgba(255, 255, 255, 200);
    stroke: rgba(255, 255, 255, 255);
    stroke-width: 1;
  }
  100% {
    fill: rgba(255, 255, 255, 255);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke: rgba(255, 255, 255, 0);
    stroke-width: 0;
  }
}

.fadeInBox {
  opacity: 0;
}

:global(.animation) .fadeInBox {
  animation: fadein 4s forwards 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

:global(.animation) .fadeOutBox {
  animation: fadeout 2.5s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
