.App {
  text-align: center;
}

.App-header {
  background: linear-gradient(to top, #1B0144, #36003C);
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.scientistContainer {
  overflow: visible;
  position: absolute;
  width: 100%;
  height: 100%
}

.scientist {
  width: 100%;
  height: 100%;
}

.content {
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: visible;
  max-width: 400px;
}

.content .logo {
  display: flex;
}

.content .words {
  margin-left: 1.35rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .content .logo {
    flex-direction: column;
    align-items: center;
  }

  .content .words {
    margin-left: 0;
  }
}

.comingSoon {
  margin-top: 4rem;
  opacity: 0;
}

.animation .comingSoon {
  opacity: 0;
  animation: 2.5s fadeInSlideUp cubic-bezier(0.33, 1, 0.68, 1) forwards 2s;
}

.links {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  opacity: 0;
}

.links svg {
  height: 2rem;
}

.links a:not(:last-child) {
  margin-right: 0.8rem;
}

.links a:not([href]) svg {
  fill: #FFFFFF55;
}

.animation .links {
  opacity: 0;
  animation: 1.5s fadeInSlideUp cubic-bezier(0.33, 1, 0.68, 1) forwards 2.5s;
}

@keyframes fadeInSlideUp {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-aspect-ratio: 149 / 100) {
  .scientist {
    opacity: 0.5;
  }

  .content {
    margin: auto;
  }
}

@media only screen and (min-aspect-ratio: 149 / 100) {
  .content {
    position: absolute;
    bottom: 40%;
    left: 60%;
  }
}
